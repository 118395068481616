<template>
<CContainer>
  <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Integrantes del Grupo</h3>
          </CCardHeader>
        </CCard>
      </CCol>
   </CRow>
   <CRow>
      <CCol col="6">
         <CCard>
          <CCardHeader>
            <h5>Actualizar Grupo</h5>
          </CCardHeader>
          <CCardBody>
            <CForm validate @submit.prevent="actualizarGrupo($event)">
               <CInput
                  label="Nombre del Grupo"
                  placeholder="Nombre del Grupo"
                  autocomplete="off"
                  minlength="3"
                  required
                  was-validated
                  v-model="grupo.nombre"
                />
                <hr>
                <CButton size="sm" color="danger" type="submit"  v-if="$can('grupos_sms.edit')">
                    Actualizar Grupo
                </CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <h5 class="text-center">Actualizar Contacto</h5>
          </CCardHeader>
          <CCardBody>
                <template v-for="(name, key) in radioNamesMetodoEnvio">
                <CRow form class="form-group" :key="name">
                  <CCol sm="12">
                    {{name}}
                  </CCol>
                  <CInputRadioGroup
                    class="col-12"
                    :options="optionsMetodoEnvio"
                    :custom="true"
                    :checked="`Option ${key}`"
                    :inline="key % 2 === 0"
                    :checked.sync="metodo_envido_checked_radio"
                  />
                </CRow>
              </template>
                <template v-if="changeRadioMetodoEnvio == 1">
                <hr>
               <!--<CRow>
                 <CCol col="12">
                      <h5></h5>
                 </CCol>
              </CRow>-->
              <CForm validate @submit.prevent="crearNuevoContactoIndividual()">
                <CRow>
                 <CCol col="12">
                     <CSelect
                            label="Pais del Celular"
                            placeholder="off"
                            :options="itemsPais"
                            required
                            was-validated
                            :value.sync="formCrearContactoIndividual.pais"
                        />
                     <CInput
                        label="Numero de Celular (Sin Prefijo del Pais)"
                        placeholder="Numero de Celular"
                        autocomplete="off"
                        minlength="10"
                        maxlength="12"
                        required
                        was-validated
                        v-model="formCrearContactoIndividual.numero"
                        />
                    <CInput
                        label="Nombre Contacto"
                        placeholder="Nombre Contacto"
                        autocomplete="off"
                        required
                        was-validated
                        v-model="formCrearContactoIndividual.nombre"
                        />
                     <hr>
                     <div class="text-center">
                         <CButton size="sm" color="success" type="submit" v-if="$can('grupos_sms.createcontacto')">
                            Crear Contacto
                        </CButton>
                     </div>
                 </CCol>
              </CRow>
              </CForm>
              </template>  
              <template v-if="changeRadioMetodoEnvio == 2">
                <hr>
                <CForm validate @submit.prevent="crearNuevoContactosArchivos">
                  <CRow>
                  <CCol col="12">
                      <label for="">Cargar una base de datos con dos columnas en formato Excel o CSV <strong>(Indicativo del pais + Numero de celular ; Nombre Concato)</strong></label>
                      <hr>
                  </CCol>
                  <CCol col="12">
                       <label for="">Seleccione un Archivo</label>
                       <input type="file" name="" id="" v-on:change="onChangeArchivoGrupo" :key="fileInputKey"  accept=".xlsx, .xls, .csv">
                        <hr>
                  </CCol>
                  <hr>
                  <CCol col="12">
                      <label for="">
                          <strong>- La primera Columna (A) debe ser siempre el numero de celular (Obligatorio)</strong><br>
                          <strong>- La segunda Columna (B) es el nombre del contacto (Opcional)</strong>
                      </label>
                      <hr>
                  </CCol>
                  <CCol col="12" class="text-center">
                      <CButton size="sm" color="success" type="submit"  v-if="$can('grupos_sms.cargararchivos')">
                          Cargar Archivo
                      </CButton>
                  </CCol>
                </CRow>
              </CForm>
              </template>  
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
     <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
           <CRow class="justify-content-center">
              <CCol col="6">
                <h5 class="text-center">Cantidad de Integrantes creados: {{$formatterNumeroTipoCreditos(file_all_grupo.cantidad)}}</h5>
              </CCol>
           </CRow>
           <CRow class="justify-content-right">
              <CCol col="6">
                <CButton size="sm" class="text-center" color="success" @click="generarReporteIntegrantes()" v-if="file_all_grupo.cantidad > 0">
                    Descargar {{$formatterNumeroTipoCreditos(file_all_grupo.cantidad)}} Integrantes creados </h5>
                 </CButton>
              </CCol>
           </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template #created_at="{item}">
              <td>
                 {{formatoFecha(item.created_at)}}
              </td>
          </template>
          <template #celular-filter="{item}">
                <br>
                 <input type="text" class="form-control form-control-sm" v-model="filtro_celular" @keyup="filtrobusquedalistanegra"> 
          </template>
          <template #acciones="{item}">
            <td>
              <CButton size="sm" color="danger" @click="abrirmodalEliminarGrupo(item)" v-if="$can('grupos_sms.deletecontacto')">
                    Eliminar Contacto
              </CButton>
            </td>
          </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <CModal
            :show.sync="modal_eliminar_grupo"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="sm"
            color="dark"
          >
            <CRow>
              <CCol col="12">
                Usted esta Apunto de Eliminar el Integrante : <strong>{{form_eliminar_integrante.celular}}</strong>
              </CCol>
            </CRow>
            <template #header>
              <h6 class="modal-title">Eliminar Integrante</h6>
              <CButtonClose @click="modal_eliminar_grupo = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="modal_eliminar_grupo = false" color="danger">Cancel</CButton>
              <CButton  color="success" @click="eliminarIntegrantePorId()" v-if="$can('grupos_sms.deleteintegrante')">Aceptar</CButton>
            </template>
          </CModal>
  </div>
</CContainer>

</template>


<script>
import EventBus from '../../modules/bus';
export default {
  name: 'IntegrantesGrupos',
  data () {
    return {
      items: [],
      fields: [
        { key: 'celular', label: 'Celular', _classes: 'font-weight-bold' },
        { key: 'nombre',label:'Nombre Contacto' },
        { key: 'created_at',label:'Fecha Creado' },
        { key: 'acciones',label:'',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0,
      nombre_grupo:'',
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Grupo Creado Correctamente.',
      modal_eliminar_grupo:false,
      form_eliminar_integrante:{
        celular:'',
        id:'',
      },
      optionsMetodoEnvio: [{label: 'Individual',value:'individual'},{label: 'Cargue Archivo',value:'archivo'}],
      radioNamesMetodoEnvio: ['Selecciona el Metodo de Crear Contactos'],
      metodo_envido_checked_radio:'individual',
      itemsPais: [{value:'57',label:'Colombia (57)'},{value:'58',label:'Venezuela (58)'}],
      formCrearContactoIndividual:{
          pais: '57',
          numero:'',
          nombre:'',
      },
      formCrearContactosArchivo:{
        archivo:null
      },
      grupo:{
        nombre: '',
        id: '',
      },
      file_all_grupo:{},
      fileInputKey:0,
      descargado:null,
      filtro_celular:'',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getInitGrupoIntegrantes(){
        const id = this.$route.params.id;
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/grupos/'+id).then(response => {
             this.grupo.nombre = response.data.nombre_grupo;
             this.grupo.id = response.data.id_grupo;
             this.file_all_grupo = response.data;
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/grupos/integrantes/'+id).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now'; 
              }
              return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    searchIntegrantes(search){
      const id = this.$route.params.id;
      this.$store.dispatch('auth/loading_http','visible');
        this.$http.post('/sms/grupos/integrantes/search',{
          //params: {
            id:id,
            search:search
          //}
        }).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now'; 
              }
              return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    getGrupoIntegrantes(){
        const id = this.$route.params.id;
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/grupos/integrantes/'+id).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/grupos/'+id).then(response => {
             this.grupo.nombre = response.data.nombre_grupo;
             this.grupo.id = response.data.id_grupo;
             this.file_all_grupo = response.data;
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    generarReporteIntegrantes(){
      this.$store.dispatch('auth/loading_http','visible');
      let id = this.grupo.id;
      this.descargado = null;
        this.$http.get('/sms/integrantes/reporte/'+id).then(response => {
             let data = response.data;
             if (data == 'OK') {
                  this.mostrarNotificacion++
                  this.mensaje_success='El Reporte Se Esta Generando Correctamente Por Favor Espere Para que se Descargue de Inmediato.'
                  this.descargado = null;
                  let timerId = setInterval(() => this.getreporte(), 5000);
                  let time2 = setInterval(() => {
                    if (this.descargado != null) {
                      clearInterval(timerId); 
                      clearInterval(time2); 
                      this.descargado = null;
                    }
                  }, 2000);
                  // después de 5 segundos parar
                  setTimeout(() => { clearInterval(timerId);clearInterval(time2);}, 500000);
             }else{
                  this.mostrarNotificacionAlerta++
                  this.mensaje_warning='No Fue Posible Descargar El Reporte'
             }
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    getreporte(){
      const id = this.$route.params.id;
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/grupos/'+id).then(response => {
          let data = response.data;
          //console.log(data);
          //console.log(this.descargado);
          if (data.nombre_archivo != null) {
              if (this.descargado == null) {
                  let nombre = data.nombre_archivo; 
                  var url = this.$apiAdress+`/reporte/descargar/general/reportes|${nombre}`
                  window.open(url, '_blank');
                  this.descargado = data.nombre_archivo;
                  this.$store.dispatch('auth/loading_http','hidden')
              }
          }
        }).catch(()=>{
             
        })
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    actualizarGrupo(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.put('/sms/grupos/'+this.grupo.id,{
        id:this.grupo.id,
        nombre:this.grupo.nombre,
      }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success='Grupo Actualizado Correctamente.'
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.getGrupoIntegrantes();
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    eliminarIntegrantePorId(){
      this.modal_eliminar_grupo = false
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.delete('/sms/grupos/integrantes/'+this.form_eliminar_integrante.id).then(response =>{
        let data = response.data;
        if (data == "OK") {
            let nombre = this.form_eliminar_integrante.celular;
            this.form_eliminar_integrante.id = ''
            this.form_eliminar_integrante.celular = ''
            this.mostrarNotificacion ++;
            this.getGrupoIntegrantes();
            this.mensaje_success=`Integrante ${nombre} Eliminado Correctamente.`,
            setTimeout(() => {this.mensaje_success='Grupo Creado Correctamente.'},6000)
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    formatoFecha(fecha){
      let fechaformato = fecha.split('.')[0];
      return fechaformato.replace('T',' ');
    },
    abrirmodalEliminarGrupo(item){
      this.modal_eliminar_grupo = true;
      this.form_eliminar_integrante.celular = item.celular;
      this.form_eliminar_integrante.id = item.id_integrante;
    },
    crearNuevoContactoIndividual(evt){
      const id = this.$route.params.id;
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post('/sms/grupos/integrantes',{
        id_grupo:id,
        pais:this.formCrearContactoIndividual.pais,
        celular:this.formCrearContactoIndividual.numero,
        nombre:this.formCrearContactoIndividual.nombre,
      }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success='Contacto Individual Creado Correctamente.'
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.formCrearContactoIndividual.pais = ''
            this.formCrearContactoIndividual.numero = ''
            this.formCrearContactoIndividual.nombre = ''
            this.getGrupoIntegrantes();
        }else if(data == "NO"){
              this.mensaje_warning = 'El Numero de Celular No es Valido en el Pais Seleccionado'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else if(data == "DUPLICADO"){
              this.mensaje_warning = 'El Numero de Celular Ya Existe en el Grupo'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    crearNuevoContactosArchivos(evt){
      const id = this.$route.params.id;
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      let InstFormData = new FormData();
      InstFormData.append('id_grupo' ,id);
      InstFormData.append('archivo' , this.formCrearContactosArchivo.archivo);
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post('/sms/grupos/integrantes',InstFormData,{
       headers : {'content-type': 'multipart/form-data'}
      }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success='Contactos Archivo Cargando ...'
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.fileInputKey++
            this.formCrearContactosArchivo.archivo = null
            // repetir con el intervalo de 2 segundos
            let timerId = setInterval(() => this.getGrupoIntegrantes(), 5000);
            // después de 5 segundos parar
            setTimeout(() => { clearInterval(timerId);}, 60000);
        }else if(data == "NO"){
              this.mensaje_warning = 'El Numero de Celular No es Valido en el Pais Seleccionado'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else if(data == "DUPLICADO"){
              this.mensaje_warning = 'El Numero de Celular Ya Existe en el Grupo'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    onChangeArchivoGrupo(event){
      this.formCrearContactosArchivo.archivo = event.target.files[0];
    },
    filtrobusquedalistanegra(event){
      let value = event.target.value;
      if (value.length > 3) {
         this.searchIntegrantes(value);
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.getInitGrupoIntegrantes();
    }, 100);
  },
  created: function() {
        EventBus.$on('mostrarNotificacion', (item) => {
          console.log(item);
            //this.comments.push(item)
        });
  },
  computed: {
    changeRadioMetodoEnvio(){
      let metodo_envido_checked = 0;
      if (this.metodo_envido_checked_radio == 'individual') {
        metodo_envido_checked = 1;
      }else if(this.metodo_envido_checked_radio == 'archivo'){
        metodo_envido_checked = 2;
      }
      return metodo_envido_checked;
    }
  }
}
</script>
